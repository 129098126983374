import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/medical',
    name: 'medical',
    component: () => import('../views/medical.vue')
  },
  {
    path: '/internet',
    name: 'internet',
    component: () => import('../views/internet.vue')
  },
  {
    path: '/retail',
    name: 'retail',
    component: () => import('../views/retail.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/details.vue')
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import('../views/solution.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/info.vue')
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import('../views/aboutus.vue')
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import('../views/contactus.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
