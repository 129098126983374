<template>
  <div id="app">
    <keep-alive include="journalism">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
export default {
  methods: {

  },
  mounted() {
    const vw = Math.max(document.documentElement.clientWidth || 0,window.innerWidth || 0)
    if (this._isMobile()) {
      console.log('手机端')
    } else {
      console.log('pc端')
      location.href = "https://www.yszkmedical.com/home";
    }
  },
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  }
}
</script>
<style  lang="scss">
@font-face {
  font-family: 'wryh';
  /* 重命名字体名 */
  src: url('./assets/family/msyh.ttf');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: wryh, Microsoft YaHei, Heiti SC, tahoma, arial, Hiragino Sans GB,
    sans-serif;
  background: #e5e5e5;
}

div,
ul,
li,
span,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
}

li {
  list-style-type: none;
}

.Profile-title {
  text-align: center;
  margin-top: 47px;
  font-size: 35px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #363636;
}

.Profile-en {
  text-align: center;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #707070;
  margin-top: 14px;
  margin-bottom: 30px;
}

.home-tab {
  display: flex;
  justify-content: space-between;
  padding: 0 33px;

  li {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #363636;
    padding-bottom: 13px;
  }

  .selectBab {
    font-weight: bold;
    position: relative;
  }

  .selectBab::after {
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translate(-50%);
    content: '';
    display: inline-block;
    width: 34px;
    height: 2px;
    background-color: #315fb4;
  }
}

.item {
  padding: 40px 20px;
}

.home-line {
  width: 100%;
  height: 1px;
  border-top: solid 1px #b8b8b8;
}

.Profile-text {
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 36px;
  letter-spacing: 0px;
  color: #707070;
  margin-top: 24px;
}

.LoveHelpStudents-text {
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 36px;
  letter-spacing: 0px;
  color: #707070;
}

.LoveHelpStudents-img {
  width: 500px;
  height: 327px;
  border-radius: 8px;
}

.All-title {
  font-size: 44px;
  font-weight: bold;
  color: #181818;
  text-align: center;
}

.All-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-top: 64px;
  > div {
    width: 340px;
    height: 388px;
    background: #ffffff;
    box-shadow: 0px 8px 16px 2px rgba(5, 36, 72, 0.12);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-right: 22px;
    margin-bottom: 22px;
    position: relative;
    overflow: hidden;
    &::after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s ease-in-out;
      opacity: 0;
    }

    &:hover {
      &::after {
        opacity: 1;
        background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/internet/15.png')
          no-repeat;
        background-size: 100% 100%;
      }
      > div {
        img:nth-child(1) {
          display: none;
        }
        img:nth-child(2) {
          display: inline-block;
        }
        > div:nth-child(3) {
          color: #ffffff;
        }

        > div:nth-child(4) {
          color: #ffffff;
        }
      }
    }
    > div {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;

      img:nth-child(1) {
        width: 64px;
        height: 64px;
        margin-top: 40px;
      }
      img:nth-child(2) {
        width: 64px;
        height: 64px;
        margin-top: 40px;
        display: none;
      }
      > div:nth-child(3) {
        font-size: 30px;
        font-weight: 500;
        color: #181818;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      > div:nth-child(4) {
        width: 292px;
        font-size: 26px;
        font-weight: 400;
        color: #7b7b7b;
        line-height: 44px;
        margin: 0 auto;
      }
    }
  }
  & div:nth-child(2n) {
    margin-right: 0;
  }
}
.All-box2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-top: 64px;
  > div {
    width: 340px;
    height: 388px;
    background: #ffffff;
    box-shadow: 0px 8px 16px 2px rgba(5, 36, 72, 0.12);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-right: 22px;
    margin-bottom: 22px;

    img:nth-child(1) {
      width: 64px;
      height: 64px;
      margin-top: 40px;
    }
    > div:nth-child(2) {
      font-size: 30px;
      font-weight: 500;
      color: #181818;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    > div:nth-child(3) {
      width: 292px;
      font-size: 26px;
      font-weight: 400;
      color: #7b7b7b;
      line-height: 44px;
      margin: 0 auto;
    }
  }
  & div:nth-child(2n) {
    margin-right: 0;
  }
}
.banner {
  width: 100%;
  height: 480px;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
  }

  > div:nth-child(2) {
    font-size: 34px;
    font-weight: 500;
    color: #ffffff;
    margin: 134px 0 20px 40px;
  }

  > div:nth-child(3) {
    width: 524px;
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
    line-height: 44px;
    margin-left: 40px;
  }
}
.home-banner {
  width: 100%;
  height: 480px;
  background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/banner/1.png')
    no-repeat;
  background-size: 100% 100%;
  padding: 76px 0 0 40px;

  > div:nth-child(1),
  > div:nth-child(2) {
    font-size: 34px;
    font-weight: 500;
    color: #ffffff;
    line-height: 52px;
  }
  > div:nth-child(3) {
    width: 492px;
    font-size: 24px;
    font-weight: 400;
    color: #d6dfe8;
    line-height: 40px;
    margin-top: 10px;
  }
  > div:nth-child(4) {
    width: 220px;
    height: 78px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 30px;
    text-align: center;
    line-height: 75px;
    margin-top: 26px;
  }
}

.powered-health {
  width: 100%;
  height: 480px;
  background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/1.png')
    no-repeat;
  background-size: 100% 100%;
  overflow: hidden;

  > div {
    width: 560px;
    font-size: 34px;
    font-weight: 400;
    color: #ffffff;
    line-height: 64px;
    text-align: center;
    margin: 117px auto 0px;
    letter-spacing: 6px;
  }
}

.program-box {
  margin-top: 64px;
  > div {
    width: 702px;
    height: 364px;
    background: #ffffff;
    box-shadow: 0px 8px 16px 2px rgba(5, 36, 72, 0.12);
    border-radius: 8px 8px 8px 8px;
    margin: 0 auto 48px;
    text-align: center;
    overflow: hidden;
    position: relative;
    &::after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s ease-in-out;
      opacity: 0;
    }
    &:hover {
      &::after {
        opacity: 1;
        background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/internet/22.png')
          no-repeat;
        background-size: 100% 100%;
      }
      > div {
        > div:nth-child(1) {
          color: #ffffff;
        }
        > div:nth-child(2) {
          color: #ffffff;
        }
        > div:nth-child(3) {
          color: #ffffff;
        }
      }
    }
    > div {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      > div:nth-child(1) {
        font-size: 40px;
        font-weight: bold;
        color: #0b61c4;
        margin-top: 64px;
        margin-bottom: 32px;
      }
      > div:nth-child(2) {
        font-size: 34px;
        font-weight: 500;
        color: #181818;
      }
      > div:nth-child(3) {
        width: 572px;
        font-size: 26px;
        font-weight: 400;
        color: #acacac;
        line-height: 44px;
        margin: 20px auto 0;
      }
    }
  }
}

.why-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    width: 340px;
    height: 408px;
    background: #ffffff;
    box-shadow: 0px 8px 16px 2px rgba(5, 36, 72, 0.12);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-right: 22px;
    margin-bottom: 22px;
    text-align: center;

    position: relative;
    &::after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s ease-in-out;
      opacity: 0;
    }

    > div {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      padding-top: 32px;
      img:nth-child(1) {
        width: 80px;
        height: 80px;
      }
      img:nth-child(2) {
        width: 80px;
        height: 80px;
        display: none;
      }
      > div:nth-child(3) {
        font-size: 34px;
        font-weight: 500;
        color: #181818;
        line-height: 64px;
        margin-top: 14px;
        margin-bottom: 14px;
      }

      > div:nth-child(4) {
        width: 292px;
        font-size: 26px;
        font-weight: 400;
        color: #7b7b7b;
        line-height: 44px;
        margin: 0 auto;
      }
    }
    &:hover {
      &::after {
        opacity: 1;
        background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/24.png')
          no-repeat;
        background-size: 100% 100%;
      }
      > div {
        img:nth-child(1) {
          display: none;
        }
        img:nth-child(2) {
          display: inline-block;
        }
        > div:nth-child(3) {
          color: #ffffff;
        }

        > div:nth-child(4) {
          color: #ffffff;
        }
      }
    }
  }

  & div:nth-child(2n) {
    margin-right: 0;
  }
}
.swiper-show {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 62px;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
  > div {
    width: 12px;
    height: 12px;
    background: rgba(255, 255, 255, 0.55);
    border-radius: 50%;
    opacity: 1;
    margin: 0 8px;
  }
  .select-show {
    background: #ffffff;
  }
}

.all-title-ani {
  transition: all;
  animation: aniTranslate 0.8s ease both;
}
.all-ani-delay {
  animation-delay: 0.3s;
}

@keyframes aniTranslate {
  0% {
    opacity: 0;
    transform: translate(180px, 0px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.ovfHiden {
  overflow: hidden;
  height: 100%;
}
</style>
