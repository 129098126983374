<template>
  <div>
    <div class="mark" ref="mark">
      <div class="alert">
        <div class="mMmenuLay on">
          <dl v-for="(item,index) in list" :key="index" @click="gotoTab(item.route)">
            <dt class="alert-item">
              <span>{{item.title}}</span>
              <i v-if="item.show==false" class="mToggle"></i>
            </dt>
            <dd v-for="i in item.children" :key="i+'a'" class="mMenu_dd2" @click="gotoTab('/solution')">
              {{i}}
            </dd>
          </dl>
        </div>
      </div>
      <div></div>
      <img :src="`${$url}/header/3.png`" class="mark-out" @click.stop="close" alt="">
    </div>
    <div class="prop" ref="prop" @click.stop="close">
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      seltctlanguage: 1,
      list: [
        {
          show: null,
          title: '首页',
          children: [],
          route: 'home'
        },
        {
          title: '医疗软件开发',
          show: null,
          children: [],
          route: 'medical'
        },
        {
          title: '物联网智慧医疗',
          show: null,
          children: [],
          route: 'internet'
        },
        {
          title: '医药新零售',
          show: null,
          children: [],
          route: 'retail'
        },
        {
          title: '解决方案',
          show: false,
          children: ['互联网医疗智慧服务'],
          route: 'solution'
        },
        {
          title: '新闻资讯',
          show: null,
          route: 'news',
          children: []
        },
        {
          title: '关于我们',
          show: null,
          route: 'aboutus',
          children: []
        },
        {
          title: '联系我们',
          show: null,
          route: 'contactUs',
          children: []
        }
      ]
    }
  },
  methods: {
    webHeader() {
      if ($(window).width() < 993) {
        $('.mMmenuLay dl').each(function (i) {
          var _this = $(this)
          if (_this.find('dd').length > 0) {
            _this.find('.mToggle').show()
          }
        })
        $('.mToggle').click(function (e) {
          e.stopPropagation()
          var _this2 = $(this)
          if (_this2.parents('dl').hasClass('on')) {
            _this2.parents('dl').removeClass('on')
            _this2.removeClass('mToggle2')

            _this2.parents('dl').find('.mToggle_a').removeClass('mToggle2_a')
            _this2.parents('dl').find('.mMenu_dd3').slideUp(300)
          } else {
            $('.mMmenuLay dl').removeClass('on')
            $('.mToggle').removeClass('mToggle2')
            _this2.addClass('mToggle2')
            _this2.parents('dl').addClass('on')

            $('.mToggle_a').removeClass('mToggle2_a')
            $('.mMenu_dd3').slideUp(300)
          }
        })

        $('.mToggle_a').click(function (event) {
          event.stopPropagation()
          var _this3 = $(this)
          _this3.toggleClass('mToggle2_a')
          _this3.parents('dd').next('.mMenu_dd3').slideToggle(300)
        })
      } else {
        $('.subNav_box dl').each(function (i) {
          if ($(this).find('dd').length < 1) {
            $(this).find('.nav_btn').hide()
          }
        })
        $('.nav_btn').click(function () {
          $(this).parents('dt').next('dd').slideToggle(300)
        })
      }
    },
    selectTab(index) {
      if (this.list[index].show) {
        this.list[index].show = false
      } else {
        this.list.forEach((item) => {
          item.show = false
        })
        this.list[index].show = true
      }
    },
    gotoTab(route) {
      this.$refs.mark.className = 'mark';
      this.$refs.prop.className = 'prop';
      setTimeout(() => {
        this.$router.push(route);
        this.$emit('setShow');
      },300)
    },
    close() {
      this.$refs.mark.className = 'mark';
      this.$refs.prop.className = 'prop';
      setTimeout(() => {
        this.$emit('setShow');
      },300)
    }
  },
  mounted() {
    this.webHeader();
    this.$refs.mark.classList.add('markOn');
    this.$refs.prop.classList.add('aniOpacity');
  }
}
</script>

<style scoped lang="scss">
.prop {
  width: 100%;
  height: 100%;
  background: #000000;
  border-radius: 0px 0px 0px 0px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.aniOpacity {
  opacity: 0.4;
}
.mark {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background: #2e59a7;
  width: 560px;
  z-index: 10001;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  transform: translateX(100%);
}

.markOn {
  opacity: 1;
  transform: translateX(0%);
}

.mToggle {
  width: 32px;
  height: 32px;
  background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/header/1.png')
    no-repeat;
  background-size: contain;
  display: block;
}
.mToggle2 {
  width: 32px;
  height: 3.2px;
  background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/header/2.png')
    no-repeat;
  background-size: contain;
  display: block;
}

.alert-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  height: 92px;
  > span:nth-child(1) {
    font-size: 30px;
    font-weight: 400;
    color: #ffffff;
  }
}
dl,
dd {
  padding: 0;
  margin: 0;
}
.mMmenuLay {
  margin: 18px 32px 0;
}

.mMenu_dd2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
  transition: all 0.4s;
  height: 0;
  line-height: 94px;
  overflow: hidden;
  padding-left: 34px;
}
.mMmenuLay .on {
  .mMenu_dd2 {
    height: 93px;
  }
}
.mark-out {
  width: 32px;
  height: 32px;
  margin-left: 32px;
  margin-top: 32px;
}
</style>

