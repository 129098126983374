<template>
  <div>
    <div class="hedaer-top">
      <img :src="`${$url}/header/logo.png`" alt="" />
      <img :src="`${$url}/header/expand.png`" alt="" @click="showPoup()" />
    </div>
    <div class="header">
      <poup v-if="show" @setItem="setItem" @setShow="setShow"></poup>
    </div>
    <div class="suspendedTab">
      <div>
        <img :src="`${$url}/fixed/1.png`" alt="" />
        <img :src="`${$url}/fixed/5.png`" alt="" />
        <div>400-681-0626</div>
        <div>热线</div>
      </div>
      <div>
        <img :src="`${$url}/fixed/2.png`" alt="" />
        <img :src="`${$url}/fixed/6.png`" alt="" />
        <div>
          <img
            src="https://static.drlianzhuren.com/brandWeb/contactUs/official-account.jpg?v=1"
            alt=""
          />
        </div>
        <div>公众号</div>
      </div>
      <div>
        <img :src="`${$url}/fixed/3.png`" alt="" />
        <img :src="`${$url}/fixed/7.png`" alt="" />
        <div>
          <img src="../assets/10.png" alt="" />
        </div>
        <div>企业微信</div>
      </div>
      <div @click="goToTop">
        <img :src="`${$url}/fixed/4.png`" alt="" style="margin-top: 0.2rem" />
        <img :src="`${$url}/fixed/8.png`" alt="" style="margin-top: 0.2rem" />
      </div>
    </div>
  </div>
</template>

<script>
import poup from "../components/headerProp.vue";
export default {
  components: { poup },
  data() {
    return {
      wow: null,
      show: false,
      isInfo: false,
      select: 1,
      selectTab: 0,
    };
  },
  beforeDestroy() {
    this.wow.stop();
  },
  mounted() {
    switch (this.$route.path) {
      case "/home":
        this.selectTab = 0;
        break;
      case "/aboutUs":
        this.selectTab = 1;
        break;
      case "/journalism":
        this.selectTab = 2;
        break;
      case "/info": 
        this.isInfo = true;
        break;
      case "/responsibility":
        this.selectTab = 3;
        break;
      case "/Investor":
        this.selectTab = 4;
        break;
      case "/business":
        this.selectTab = 5;
        break;
      case "/contactUs":
        this.selectTab = 6;
        break;
    }
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
  },
  methods: {
    goToTop() {
      document.documentElement.scrollTop = 0;
    },
    setItem(a) {
      this.select = a;
      this.$emit("setSelect", this.select);
    },
    setShow() {
      $("html,body").removeClass("ovfHiden"); // 使网页恢复可滚动
      this.show = false;
    },
    showPoup() {
      $("html,body").addClass("ovfHiden"); // 使网页不可滚动
      this.show = true;
    },
  },
};
</script>

<style lang="scss">
.hedaer-top {
  position: fixed;
  z-index: 999;
  width: 750px;
  height: 112px;
  background: #e6eff9;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  & img:nth-child(1) {
    width: 240px;
  }

  & img:nth-child(2) {
    width: 44px;
  }
}

.header {
  padding-top: 112px;
}

.suspendedTab {
  display: flex;
  position: fixed;
  z-index: 10000;
  right: 32px;
  bottom: 400px;
  width: 92px;
  height: 92px;
  flex-wrap: wrap;
  > div {
    width: 92px;
    height: 92px;
    margin-bottom: 12px;
    background: #ffffff;
    box-shadow: 0px 8px 24px 2px rgba(0, 51, 101, 0.12);
    border-radius: 16px 16px 16px 16px;
    opacity: 1;
    position: relative;
    text-align: center;
    padding-top: 13px;
    > div:nth-child(4) {
      font-size: 20px;
      font-weight: 400;
      color: #7b7b7b;
      margin-top: 4px;
    }
    &:hover {
      img:nth-child(1) {
        width: 40px;
        display: none;
      }
      img:nth-child(2) {
        width: 40px;
        display: block;
      }
      div:nth-child(4) {
        color: #0b61c4 !important;
      }
    }
    img:nth-child(1) {
      width: 40px;
      margin: 0 auto;
      display: block;
    }
    img:nth-child(2) {
      width: 40px;
      margin: 0 auto;
      display: none;
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      > div:nth-child(3) {
        display: none;
      }
    }
    &:nth-child(1) {
      &:hover {
        > div:nth-child(3) {
          width: 280px;
          height: 90px;
          background: #ffffff;
          box-shadow: 0px 8px 24px 2px rgba(0, 51, 101, 0.12);
          border-radius: 16px 16px 16px 16px;
          opacity: 1;
          font-size: 32px;
          font-weight: 400;
          color: #0b61c4;
          text-align: center;
          line-height: 90px;
          display: block;
          position: absolute;
          right: 110px;
          top: 0px;
        }
      }
    }
    &:nth-child(2) {
      &:hover {
        > div:nth-child(3) {
          width: 200px;
          height: 200px;
          background: #ffffff;
          box-shadow: 0px 8px 24px 2px rgba(0, 51, 101, 0.12);
          border-radius: 16px 16px 16px 16px;
          opacity: 1;
          position: absolute;
          right: 112px;
          top: -55px;
          display: block;
          padding: 16px;
          > img {
            width: 100%;
            display: block;
          }
        }
      }
    }
    &:nth-child(3) {
      &:hover {
        > div:nth-child(3) {
          width: 200px;
          height: 200px;
          background: #ffffff;
          box-shadow: 0px 8px 24px 2px rgba(0, 51, 101, 0.12);
          border-radius: 16px 16px 16px 16px;
          opacity: 1;
          position: absolute;
          right: 112px;
          top: -50px;
          display: block;
          padding: 16px;
          > img {
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
}
</style>
